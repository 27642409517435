import apiClient from "@/api/api-client";

export const getTarifList = async (filters) => {
  let { data } = await apiClient.get(`/tariff`, {
    params: filters,
  });
  return data;
};

export const getItemTariff = async (id) => {
  let { data } = await apiClient.get(`/tariff/${id}`);
  return data;
};

export const createTariff = async (form) => {
  let { data } = await apiClient.post(`/tariff`, form);
  return data;
};

export const updateTariff = async (uid, form) => {
  let { data } = await apiClient.post(`/tariff/${uid}`, {
    action: 'edit',
    ...form
  });
  return data;
};


export const deleteTariff = async (uid)=> {
  let {data} = await apiClient.post(`/tariff/${uid}`, {
    action: 'delete'
  })
  return data;
}
